import React from "react";
import styles from "./index.module.sass";

const Divider = () => (
  <div className={styles.container}>
    {/* <div className={styles.divider} /> */}
  </div>
);

export default Divider;
