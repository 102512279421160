import React from "react";
import styles from "./index.module.sass";

const Image = props => {
  const { src, alt, legend } = props;
  return (
    <div className={styles.container}>
      <img src={src} alt={alt} className={styles.image} />
      {legend && <h5>{legend}</h5>}
    </div>
  );
};

export default Image;
