import React from "react";
import { Link } from "gatsby";

import styles from "./css/index.module.sass";
import Layout from "../components/layout";
import SEO from "../components/seo";

import publication002 from "../images/publications/publication_002.jpg";
import publication003 from "../images/publications/publication_003.jpg";
import publication004 from "../images/publications/publication_004.jpg";

import Article from "../components/Article";
import Divider from "../components/Common/Divider";
import BackArrowTitle from "../components/Common/BackArrowTitle";

const articles = [
  {
    title: "Zone piégée",
    content: [
      "8 juin 1944, Saint-Côme-du-Mont",
      "377th PFAB, 501st PIR",
      "Les rapports remontent, le début de l’accrochage a commencé avec une explosion. Le doute d’une mine plane. Aucune prise de risque sur cette zone. Un spécialiste du 377th PFAB, détecteur en main, balaie la zone afin de découvrir le moindre objet suspect. Suivi par un spécialiste en démolition du 501st, le binôme découvrira un obus de mortier piégé sur leur passage.",
    ],
    pictures: [
      { src: publication002, alt: "002", legend: "John  Photography" },
    ],
  },
  {
    title: "Souvenir",
    content: [
      "8 juin 1944, Saint-Côme-du-Mont",
      "Après un accrochage avec un groupe de FallschirmJäger, il est indispensable d’immortaliser ce moment. Ces hommes n’hésitent pas à s’emparer de l'équipement ennemi et de l'exhiber tel un trophée.",
    ],
    pictures: [
      { src: publication003, alt: "003", legend: "John  Photography" },
    ],
  },
  {
    title: "Utah Beach",
    content: [
      "7 juin 1944, Utah Beach",
      "La bataille de Normandie s’achève plutôt bien pour ce para du 501st blessé au bras. Son camarade le retrouve lors d’une escorte de prisonniers.",
      "Ces deux troopers « taille le bout d’gras » avec deux medics de la 1st ESB.",
    ],
    pictures: [
      { src: publication004, alt: "004", legend: "John  Photography" },
    ],
  },
];

const StoriesPage = () => (
  <Layout>
    <SEO title="Stories" />
    <div className={styles.paragraphs}>
      <BackArrowTitle title="Les petites histoires" />
      {articles.map((article, index) => {
        const { title, content, pictures } = article;
        return (
          <React.Fragment>
            <Article title={title} content={content} pictures={pictures} />
            {index < articles.length - 1 && <Divider />}
          </React.Fragment>
        );
      })}
    </div>
  </Layout>
);

export default StoriesPage;
